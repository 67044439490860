import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import shallowDiff from "shallow-diff";

import {
  EGuideDetailTypeSortField,
  IGuideDetailType,
  PGuideDetailType,
  PGuideDetailTypeFilter,
} from "../entities";
import IGuideDetailTypeRepository from "../repository";
import IGuideDetailTypeUsecase from "../usecase";

export default class GuideDetailTypeUsecase
  implements IGuideDetailTypeUsecase<CancelTokenSource> {
  private guideDetailTypeRepo: IGuideDetailTypeRepository<CancelTokenSource>;

  constructor(
    guideDetailTypeRepo: IGuideDetailTypeRepository<CancelTokenSource>
  ) {
    this.guideDetailTypeRepo = guideDetailTypeRepo;
  }

  async getGuideDetailType(
    pagination: IPagination,
    filter: PGuideDetailTypeFilter,
    sorts: ISort<EGuideDetailTypeSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IGuideDetailType[], IMeta]> {
    return await this.guideDetailTypeRepo.getGuideDetailType(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getGuideDetailTypeById(id: number): Promise<IGuideDetailType> {
    return await this.guideDetailTypeRepo.getGuideDetailTypeById(id);
  }

  async createGuideDetailType(
    guideDetailType: PGuideDetailType
  ): Promise<void> {
    await this.guideDetailTypeRepo.createGuideDetailType(guideDetailType);
  }

  async updateGuideDetailType(
    oldGuideDetailType: PGuideDetailType,
    newGuideDetailType: PGuideDetailType
  ): Promise<void> {
    const diffGuideDetailType = _.pick(
      newGuideDetailType,
      shallowDiff(oldGuideDetailType, newGuideDetailType).updated
    ) as PGuideDetailType;

    await this.guideDetailTypeRepo.updateGuideDetailType(
      oldGuideDetailType.id,
      diffGuideDetailType
    );
  }
}
