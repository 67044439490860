import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { produce } from "immer";
import { Reducer } from "redux";
import {
  EPaymentConfirmationLegitCheckSortField,
  IPaymentConfirmationLegitCheck,
  PPaymentConfirmationLegitCheckFilter,
} from "src/core/paymentConfirmationLegitCheck/entities";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType,
} from "typesafe-actions";

/*
 * state
 */
export interface IPaymentConfirmationLegitCheckState {
  paymentConfirmationLegitChecks: IPaymentConfirmationLegitCheck[];
  loading: boolean;
  error: unknown;
  meta: IMeta;
  filter: PPaymentConfirmationLegitCheckFilter;
  pagination: IPagination;
  sorts: ISort<EPaymentConfirmationLegitCheckSortField>[];
  refreshDelay: number;
}

const INITIAL_STATE: IPaymentConfirmationLegitCheckState = {
  paymentConfirmationLegitChecks: [],
  loading: false,
  error: null,
  meta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  filter: {},
  pagination: {
    limit: 10,
    offset: 0,
  },
  sorts: [],
  refreshDelay: 5000,
};

/*
 * action types
 */
const FETCH_REQUESTED = "payment-confirmation-legit-check/FETCH_REQUESTED";
const FETCH_SUCCEEDED = "payment-confirmation-legit-check/FETCH_SUCCEEDED";
const FETCH_FAILED = "payment-confirmation-legit-check/FETCH_FAILED";
const SET_FILTER_PAGINATION_SORT =
  "payment-confirmation-legit-check/SET_FILTER_PAGINATION_SORT";
const SET_REFRESH_DELAY = "payment-confirmation-legit-check/SET_REFRESH_DELAY";
const POLLING_START_REQUESTED =
  "payment-confirmation-legit-check/POLLING_START_REQUESTED";
const POLLING_STOP_REQUESTED =
  "payment-confirmation-legit-check/POLLING_STOP_REQUESTED";

/*
 * action creators
 */
export const actions = {
  setFilterPaginationSort: createAction(SET_FILTER_PAGINATION_SORT)<{
    filter: PPaymentConfirmationLegitCheckFilter;
    pagination: IPagination;
    sorts: ISort<EPaymentConfirmationLegitCheckSortField>[];
  }>(),

  setRefreshDelay: createAction(SET_REFRESH_DELAY)<{
    refreshDelay: number;
  }>(),

  fetchAsync: createAsyncAction(FETCH_REQUESTED, FETCH_SUCCEEDED, FETCH_FAILED)<
    unknown,
    {
      data: IPaymentConfirmationLegitCheck[];
      meta: IMeta;
    },
    unknown
  >(),

  pollingStartRequested: createAction(POLLING_START_REQUESTED)(),

  pollingStopRequested: createAction(POLLING_STOP_REQUESTED)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IPaymentConfirmationLegitCheckState, TActions> = (
  state = INITIAL_STATE,
  action
) => {
  return produce<IPaymentConfirmationLegitCheckState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.setFilterPaginationSort): {
        const { payload } = action;
        draft.filter = payload.filter;
        draft.pagination = payload.pagination;
        draft.sorts = payload.sorts;
        break;
      }
      case getType(actions.setRefreshDelay): {
        const { payload } = action;
        draft.refreshDelay = payload.refreshDelay;
        break;
      }
      case getType(actions.fetchAsync.request): {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case getType(actions.fetchAsync.success): {
        const {
          payload: { data, meta },
        } = action;
        draft.paymentConfirmationLegitChecks = data;
        draft.loading = false;
        draft.error = null;
        draft.meta = meta;
        break;
      }
      case getType(actions.fetchAsync.failure): {
        const { payload } = action;
        draft.loading = false;
        draft.error = payload;
        break;
      }
    }
  });
};

export default reducer;
