import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import celestineClientFactory from "src/apis/celestine";
import covidClientFactory from "src/apis/covid";

import IAuthRepository from "./auth/repository";
import AuthRepository from "./auth/repository/repository";
import IAuthUsecase from "./auth/usecase";
import AuthUsecase from "./auth/usecase/usecase";
import IAuthenticationRepository from "./authentication/repository";
import AuthenticationRepository from "./authentication/repository/repository";
import IAuthenticationUsecase from "./authentication/usecase";
import AuthenticationUsecase from "./authentication/usecase/usecase";
import IBankRepository from "./bank/repository";
import BankRepository from "./bank/repository/repository";
import IBankUsecase from "./bank/usecase";
import BankUsecase from "./bank/usecase/usecase";
import IConsignmentRepository from "./consignment/repository";
import ConsignmentRepository from "./consignment/repository/repository";
import IConsignmentUsecase from "./consignment/usecase";
import ConsignmentUsecase from "./consignment/usecase/usecase";
import ICurrencyRateRepository from "./currencyRate/repository";
import CurrencyRateRepository from "./currencyRate/repository/repository";
import ICurrencyRateUsecase from "./currencyRate/usecase";
import CurrencyRateUsecase from "./currencyRate/usecase/usecase";
import IExpenseRepository from "./expense/repository";
import ExpenseRepository from "./expense/repository/repository";
import IExpenseUsecase from "./expense/usecase";
import ExpenseUsecase from "./expense/usecase/usecase";
import IGuideDetailRepository from "./guideDetail/repository";
import GuideDetailRepository from "./guideDetail/repository/repository";
import IGuideDetailUsecase from "./guideDetail/usecase";
import GuideDetailUsecase from "./guideDetail/usecase/usecase";
import IGuideDetailTypeRepository from "./guideDetailType/repository";
import GuideDetailTypeRepository from "./guideDetailType/repository/repository";
import IGuideDetailTypeUsecase from "./guideDetailType/usecase";
import GuideDetailTypeUsecase from "./guideDetailType/usecase/usecase";
import IInvoiceLegitCheckRepository from "./invoiceLegitCheck/repository";
import InvoiceLegitCheckRepository from "./invoiceLegitCheck/repository/repository";
import IInvoiceLegitCheckUsecase from "./invoiceLegitCheck/usecase";
import InvoiceLegitCheckUsecase from "./invoiceLegitCheck/usecase/usecase";
import IInvoicePackageRepository from "./invoicePackage/repository";
import InvoicePackageRepository from "./invoicePackage/repository/repository";
import IInvoicePackageUsecase from "./invoicePackage/usecase";
import InvoicePackageUsecase from "./invoicePackage/usecase/usecase";
import ILegitCheckRepository from "./legitCheck/repository";
import LegitCheckRepository from "./legitCheck/repository/repository";
import ILegitCheckUsecase from "./legitCheck/usecase";
import LegitCheckUsecase from "./legitCheck/usecase/usecase";
import ILegitCheckBrandRepository from "./legitCheckBrand/repository";
import LegitCheckBrandRepository from "./legitCheckBrand/repository/repository";
import ILegitCheckBrandUsecase from "./legitCheckBrand/usecase";
import LegitCheckBrandUsecase from "./legitCheckBrand/usecase/usecase";
import ILegitCheckTypeRepository from "./legitCheckType/repository";
import LegitCheckTypeRepository from "./legitCheckType/repository/repository";
import ILegitCheckTypeUsecase from "./legitCheckType/usecase";
import LegitCheckTypeUsecase from "./legitCheckType/usecase/usecase";
import INotificationRepository from "./notification/repository";
import NotificationRepository from "./notification/repository/repository";
import INotificationUsecase from "./notification/usecase";
import NotificationUsecase from "./notification/usecase/usecase";
import IPackageCouponEffectRepository from "./packageCouponEffect/repository";
import PackageCouponEffectRepository from "./packageCouponEffect/repository/repository";
import IPackageCouponEffectUsecase from "./packageCouponEffect/usecase";
import PackageCouponEffectUsecase from "./packageCouponEffect/usecase/usecase";
import IPackageListRepository from "./packageList/repository";
import PackageListRepository from "./packageList/repository/repository";
import IPackageListUsecase from "./packageList/usecase";
import PackageListUsecase from "./packageList/usecase/usecase";
import IPackageSetRepository from "./packageSet/repository";
import PackageSetRepository from "./packageSet/repository/repository";
import IPackageSetUsecase from "./packageSet/usecase";
import PackageSetUsecase from "./packageSet/usecase/usecase";
import IPaymentConfirmationRepository from "./paymentConfirmation/repository";
import PaymentConfirmationRepository from "./paymentConfirmation/repository/repository";
import IPaymentConfirmationUsecase from "./paymentConfirmation/usecase";
import PaymentConfirmationUsecase from "./paymentConfirmation/usecase/usecase";
import IPaymentConfirmationLegitCheckRepository from "./paymentConfirmationLegitCheck/repository";
import PaymentConfirmationLegitCheckRepository from "./paymentConfirmationLegitCheck/repository/repository";
import IPaymentConfirmationLegitCheckUsecase from "./paymentConfirmationLegitCheck/usecase";
import PaymentConfirmationLegitCheckUsecase from "./paymentConfirmationLegitCheck/usecase/usecase";
import IPaymentConfirmationPackageRepository from "./paymentConfirmationPackage/repository";
import PaymentConfirmationPackageRepository from "./paymentConfirmationPackage/repository/repository";
import IPaymentConfirmationPackageUsecase from "./paymentConfirmationPackage/usecase";
import PaymentConfirmationPackageUsecase from "./paymentConfirmationPackage/usecase/usecase";
import IProductRepository from "./product/repository";
import ProductRepository from "./product/repository/repository";
import IProductUsecase from "./product/usecase";
import ProductUsecase from "./product/usecase/usecase";
import IProductBrandRepository from "./productBrand/repository";
import ProductBrandRepository from "./productBrand/repository/repository";
import IProductBrandUsecase from "./productBrand/usecase";
import ProductBrandUsecase from "./productBrand/usecase/usecase";
import IProductCategoryRepository from "./productCategory/repository";
import ProductCategoryRepository from "./productCategory/repository/repository";
import IProductCategoryUsecase from "./productCategory/usecase";
import ProductCategoryUsecase from "./productCategory/usecase/usecase";
import IProductCategoryGroupRepository from "./productCategoryGroup/repository";
import ProductCategoryGroupRepository from "./productCategoryGroup/repository/repository";
import IProductCategoryGroupUsecase from "./productCategoryGroup/usecase";
import ProductCategoryGroupUsecase from "./productCategoryGroup/usecase/usecase";
import IRevenueRepository from "./revenue/repository";
import RevenueRepository from "./revenue/repository/repository";
import IRevenueUsecase from "./revenue/usecase";
import RevenueUsecase from "./revenue/usecase/usecase";
import IStatisticRepository from "./statistic/repository";
import StatisticRepository from "./statistic/repository/repository";
import IStatisticUsecase from "./statistic/usecase";
import StatisticUsecase from "./statistic/usecase/usecase";
import IUserRepository from "./user/repository";
import UserRepository from "./user/repository/repository";
import IUserUsecase from "./user/usecase";
import UserUsecase from "./user/usecase/usecase";

interface IRepositories {
  authRepository: IAuthRepository;
  authenticationRepository: IAuthenticationRepository<CancelTokenSource>;
  bankRepository: IBankRepository<CancelTokenSource>;
  consignmentRepository: IConsignmentRepository<CancelTokenSource>;
  currencyRateRepository: ICurrencyRateRepository<CancelTokenSource>;
  expenseRepository: IExpenseRepository<CancelTokenSource>;
  guideDetailRepository: IGuideDetailRepository<CancelTokenSource>;
  guideDetailTypeRepository: IGuideDetailTypeRepository<CancelTokenSource>;
  invoiceLegitCheckRepository: IInvoiceLegitCheckRepository<CancelTokenSource>;
  invoicePackageRepository: IInvoicePackageRepository<CancelTokenSource>;
  legitCheckRepository: ILegitCheckRepository;
  legitCheckBrandRepository: ILegitCheckBrandRepository<CancelTokenSource>;
  legitCheckTypeRepository: ILegitCheckTypeRepository<CancelTokenSource>;
  notificationRepository: INotificationRepository;
  packageCouponEffectRepository: IPackageCouponEffectRepository<
    CancelTokenSource
  >;
  packageListRepository: IPackageListRepository<CancelTokenSource>;
  packageSetRepository: IPackageSetRepository<CancelTokenSource>;
  paymentConfirmationRepository: IPaymentConfirmationRepository;
  paymentConfirmationLegitCheckRepository: IPaymentConfirmationLegitCheckRepository<
    CancelTokenSource
  >;
  paymentConfirmationPackageRepository: IPaymentConfirmationPackageRepository<
    CancelTokenSource
  >;
  productRepository: IProductRepository<CancelTokenSource>;
  productBrandRepository: IProductBrandRepository<CancelTokenSource>;
  productCategoryRepository: IProductCategoryRepository<CancelTokenSource>;
  productCategoryGroupRepository: IProductCategoryGroupRepository<
    CancelTokenSource
  >;
  revenueRepository: IRevenueRepository<CancelTokenSource>;
  statisticRepository: IStatisticRepository<CancelTokenSource>;
  userRepository: IUserRepository<CancelTokenSource>;
}

function initRepositories(
  celestineClientFactory: ApiClientFactory
): IRepositories {
  return {
    authRepository: new AuthRepository(celestineClientFactory),
    authenticationRepository: new AuthenticationRepository(covidClientFactory),
    bankRepository: new BankRepository(covidClientFactory),
    consignmentRepository: new ConsignmentRepository(covidClientFactory),
    currencyRateRepository: new CurrencyRateRepository(covidClientFactory),
    expenseRepository: new ExpenseRepository(covidClientFactory),
    guideDetailRepository: new GuideDetailRepository(covidClientFactory),
    guideDetailTypeRepository: new GuideDetailTypeRepository(
      covidClientFactory
    ),
    invoiceLegitCheckRepository: new InvoiceLegitCheckRepository(
      covidClientFactory
    ),
    invoicePackageRepository: new InvoicePackageRepository(covidClientFactory),
    legitCheckRepository: new LegitCheckRepository(covidClientFactory),
    legitCheckBrandRepository: new LegitCheckBrandRepository(
      covidClientFactory
    ),
    legitCheckTypeRepository: new LegitCheckTypeRepository(covidClientFactory),
    notificationRepository: new NotificationRepository(covidClientFactory),
    packageCouponEffectRepository: new PackageCouponEffectRepository(
      covidClientFactory
    ),
    packageListRepository: new PackageListRepository(covidClientFactory),
    packageSetRepository: new PackageSetRepository(covidClientFactory),
    paymentConfirmationRepository: new PaymentConfirmationRepository(
      covidClientFactory
    ),
    paymentConfirmationLegitCheckRepository: new PaymentConfirmationLegitCheckRepository(
      covidClientFactory
    ),
    paymentConfirmationPackageRepository: new PaymentConfirmationPackageRepository(
      covidClientFactory
    ),
    productRepository: new ProductRepository(celestineClientFactory),
    productBrandRepository: new ProductBrandRepository(celestineClientFactory),
    productCategoryGroupRepository: new ProductCategoryGroupRepository(
      celestineClientFactory
    ),
    productCategoryRepository: new ProductCategoryRepository(
      celestineClientFactory
    ),
    revenueRepository: new RevenueRepository(covidClientFactory),
    statisticRepository: new StatisticRepository(celestineClientFactory),
    userRepository: new UserRepository(celestineClientFactory),
  };
}

interface IUsecases {
  auth: IAuthUsecase;
  authentication: IAuthenticationUsecase<CancelTokenSource>;
  bank: IBankUsecase<CancelTokenSource>;
  consignment: IConsignmentUsecase<CancelTokenSource>;
  currencyRate: ICurrencyRateUsecase<CancelTokenSource>;
  expense: IExpenseUsecase<CancelTokenSource>;
  guideDetail: IGuideDetailUsecase<CancelTokenSource>;
  guideDetailType: IGuideDetailTypeUsecase<CancelTokenSource>;
  invoiceLegitCheck: IInvoiceLegitCheckUsecase<CancelTokenSource>;
  invoicePackage: IInvoicePackageUsecase<CancelTokenSource>;
  legitCheck: ILegitCheckUsecase;
  legitCheckBrand: ILegitCheckBrandUsecase<CancelTokenSource>;
  legitCheckType: ILegitCheckTypeUsecase<CancelTokenSource>;
  notification: INotificationUsecase;
  packageCouponEffect: IPackageCouponEffectUsecase<CancelTokenSource>;
  packageList: IPackageListUsecase<CancelTokenSource>;
  packageSet: IPackageSetUsecase<CancelTokenSource>;
  paymentConfirmation: IPaymentConfirmationUsecase;
  paymentConfirmationLegitCheck: IPaymentConfirmationLegitCheckUsecase<
    CancelTokenSource
  >;
  paymentConfirmationPackage: IPaymentConfirmationPackageUsecase<
    CancelTokenSource
  >;
  product: IProductUsecase<CancelTokenSource>;
  productBrand: IProductBrandUsecase<CancelTokenSource>;
  productCategoryGroup: IProductCategoryGroupUsecase<CancelTokenSource>;
  productCategory: IProductCategoryUsecase<CancelTokenSource>;
  revenue: IRevenueUsecase<CancelTokenSource>;
  statistic: IStatisticUsecase<CancelTokenSource>;
  user: IUserUsecase<CancelTokenSource>;
}

function initUsecases(repos: IRepositories): IUsecases {
  return {
    auth: new AuthUsecase(repos.authRepository),
    authentication: new AuthenticationUsecase(repos.authenticationRepository),
    bank: new BankUsecase(repos.bankRepository),
    consignment: new ConsignmentUsecase(repos.consignmentRepository),
    currencyRate: new CurrencyRateUsecase(repos.currencyRateRepository),
    expense: new ExpenseUsecase(repos.expenseRepository),
    guideDetail: new GuideDetailUsecase(repos.guideDetailRepository),
    guideDetailType: new GuideDetailTypeUsecase(
      repos.guideDetailTypeRepository
    ),
    invoiceLegitCheck: new InvoiceLegitCheckUsecase(
      repos.invoiceLegitCheckRepository
    ),
    invoicePackage: new InvoicePackageUsecase(repos.invoicePackageRepository),
    legitCheck: new LegitCheckUsecase(repos.legitCheckRepository),
    legitCheckBrand: new LegitCheckBrandUsecase(
      repos.legitCheckBrandRepository
    ),
    legitCheckType: new LegitCheckTypeUsecase(repos.legitCheckTypeRepository),
    notification: new NotificationUsecase(repos.notificationRepository),
    packageCouponEffect: new PackageCouponEffectUsecase(
      repos.packageCouponEffectRepository
    ),
    packageList: new PackageListUsecase(repos.packageListRepository),
    packageSet: new PackageSetUsecase(repos.packageSetRepository),
    paymentConfirmation: new PaymentConfirmationUsecase(
      repos.paymentConfirmationRepository
    ),
    paymentConfirmationLegitCheck: new PaymentConfirmationLegitCheckUsecase(
      repos.paymentConfirmationLegitCheckRepository
    ),
    paymentConfirmationPackage: new PaymentConfirmationPackageUsecase(
      repos.paymentConfirmationPackageRepository
    ),
    product: new ProductUsecase(repos.productRepository),
    productBrand: new ProductBrandUsecase(repos.productBrandRepository),
    productCategory: new ProductCategoryUsecase(
      repos.productCategoryRepository
    ),
    productCategoryGroup: new ProductCategoryGroupUsecase(
      repos.productCategoryGroupRepository
    ),
    revenue: new RevenueUsecase(repos.revenueRepository),
    statistic: new StatisticUsecase(repos.statisticRepository),
    user: new UserUsecase(repos.userRepository),
  };
}

const repos = initRepositories(celestineClientFactory);
const Interactors: IUsecases = initUsecases(repos);

export default Interactors;
