import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EPaymentConfirmationLegitCheckSortField,
  IPaymentConfirmationLegitCheck,
  PPaymentConfirmationLegitCheck,
  PPaymentConfirmationLegitCheckFilter,
} from "../entities";
import IPaymentConfirmationLegitCheckRepository from "../repository";
import { IUpdateRequest } from "../request/request";

export default class PaymentConfirmationLegitCheckRepository
  implements IPaymentConfirmationLegitCheckRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private PAYMENT_CONFIRMATION_LEGIT_CHECK_ROUTE =
    "/admin/legit-check-payment-confirmation";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PPaymentConfirmationLegitCheckFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getPaymentConfirmationLegitCheck(
    pagination: IPagination,
    filter: PPaymentConfirmationLegitCheckFilter,
    sorts: ISort<EPaymentConfirmationLegitCheckSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IPaymentConfirmationLegitCheck[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.PAYMENT_CONFIRMATION_LEGIT_CHECK_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getPaymentConfirmationLegitCheckByCode(
    code: string
  ): Promise<IPaymentConfirmationLegitCheck> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(
        `${this.PAYMENT_CONFIRMATION_LEGIT_CHECK_ROUTE}/${code}`
      )
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async createPaymentConfirmationLegitCheck(
    paymentConfirmationLegitCheck: PPaymentConfirmationLegitCheck
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        this.PAYMENT_CONFIRMATION_LEGIT_CHECK_ROUTE,
        paymentConfirmationLegitCheck
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updatePaymentConfirmationLegitCheck(
    code: string,
    updateRequest: IUpdateRequest
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.PAYMENT_CONFIRMATION_LEGIT_CHECK_ROUTE}/${code}`,
        updateRequest
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
