import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EPaymentConfirmationPackageSortField,
  IPaymentConfirmationPackage,
  PPaymentConfirmationPackageFilter,
} from "../entities";
import IPaymentConfirmationPackageRepository from "../repository";
import { IUpdateRequest } from "../request/request";
import IPaymentConfirmationPackageUsecase from "../usecase";

export default class PaymentConfirmationPackageUsecase
  implements IPaymentConfirmationPackageUsecase<CancelTokenSource> {
  private repo: IPaymentConfirmationPackageRepository<CancelTokenSource>;

  constructor(
    bankRepo: IPaymentConfirmationPackageRepository<CancelTokenSource>
  ) {
    this.repo = bankRepo;
  }

  async getPaymentConfirmationPackage(
    pagination: IPagination,
    filter: PPaymentConfirmationPackageFilter,
    sorts: ISort<EPaymentConfirmationPackageSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IPaymentConfirmationPackage[], IMeta]> {
    return await this.repo.getPaymentConfirmationPackage(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getPaymentConfirmationPackageByCode(
    code: string
  ): Promise<IPaymentConfirmationPackage> {
    return await this.repo.getPaymentConfirmationPackageByCode(code);
  }

  async updatePaymentConfirmationPackage(
    code: string,
    updateRequest: IUpdateRequest
  ): Promise<void> {
    await this.repo.updatePaymentConfirmationPackage(code, updateRequest);
  }
}
