import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducer as formReducer } from "redux-form";
import { combineEpics, createEpicMiddleware } from "redux-observable";

import authReducer from "./auth/ducks";
import authenticationReducer from "./authentication/ducks";
import authenticationFlows from "./authentication/flows";
import bankReducer from "./bank/ducks";
import bankFlows from "./bank/flows";
import consignmentReducer from "./consignment/ducks";
import consignmentEpics from "./consignment/epics";
import currencyRateReducer from "./currencyRate/ducks";
import currencyRateFlows from "./currencyRate/flows";
import expenseReducer from "./expense/ducks";
import expenseFlows from "./expense/flows";
import guideDetailReducer from "./guideDetail/ducks";
import guideDetailFlows from "./guideDetail/flows";
import guideDetailTypeReducer from "./guideDetailType/ducks";
import guideDetailTypeEpics from "./guideDetailType/epics";
import invoiceLegitCheckReducer from "./invoiceLegitCheck/ducks";
import invoiceLegitCheckFlows from "./invoiceLegitCheck/flows";
import invoicePackageReducer from "./invoicePackage/ducks";
import invoicePackageFlows from "./invoicePackage/flows";
import legitCheckBrandReducer from "./legitCheckBrand/ducks";
import legitCheckBrandEpics from "./legitCheckBrand/epics";
import legitCheckTypeReducer from "./legitCheckType/ducks";
import legitCheckTypeEpics from "./legitCheckType/epics";
import packageCouponEffectReducer from "./packageCouponEffect/ducks";
import packageCouponEffectEpics from "./packageCouponEffect/epics";
import packageListReducer from "./packageList/ducks";
import packageListEpics from "./packageList/epics";
import packageSetReducer from "./packageSet/ducks";
import packageSetFlows from "./packageSet/flows";
import paymentConfirmationLegitCheckReducer from "./paymentConfirmationLegitCheck/ducks";
import paymentConfirmationLegitCheckFlows from "./paymentConfirmationLegitCheck/flows";
import paymentConfirmationPackageReducer from "./paymentConfirmationPackage/ducks";
import paymentConfirmationPackageFlows from "./paymentConfirmationPackage/flows";
import revenueReducer from "./revenue/ducks";
import revenueFlows from "./revenue/flows";
import statisticReducer from "./statistic/ducks";
import statisticEpics from "./statistic/epics";

export const rootReducer = combineReducers({
  auth: authReducer,
  authentication: authenticationReducer,
  bank: bankReducer,
  consignment: consignmentReducer,
  currencyRate: currencyRateReducer,
  expense: expenseReducer,
  guideDetail: guideDetailReducer,
  guideDetailType: guideDetailTypeReducer,
  invoiceLegitCheck: invoiceLegitCheckReducer,
  invoicePackage: invoicePackageReducer,
  legitCheckBrand: legitCheckBrandReducer,
  legitCheckType: legitCheckTypeReducer,
  packageCouponEffect: packageCouponEffectReducer,
  packageList: packageListReducer,
  packageSet: packageSetReducer,
  paymentConfirmationLegitCheck: paymentConfirmationLegitCheckReducer,
  paymentConfirmationPackage: paymentConfirmationPackageReducer,
  revenue: revenueReducer,
  statistic: statisticReducer,
  form: formReducer,
});

export const rootEpic = combineEpics(
  ...authenticationFlows,
  ...bankFlows,
  ...consignmentEpics,
  ...currencyRateFlows,
  ...expenseFlows,
  ...guideDetailFlows,
  ...guideDetailTypeEpics,
  ...invoiceLegitCheckFlows,
  ...invoicePackageFlows,
  ...legitCheckBrandEpics,
  ...legitCheckTypeEpics,
  ...packageCouponEffectEpics,
  ...packageListEpics,
  ...packageSetFlows,
  ...paymentConfirmationLegitCheckFlows,
  ...paymentConfirmationPackageFlows,
  ...revenueFlows,
  ...statisticEpics
);

export function configureStore() {
  const epicMiddleware = createEpicMiddleware();
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(rootEpic);
  return store;
}

export type RootState = ReturnType<typeof rootReducer>;
