import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import moment from "moment";

import {
  EProductSortField,
  IProduct,
  PProduct,
  PProductFilter,
} from "../entities";
import IProductRepository from "../repository";
import IProductUsecase from "../usecase";

export default class ProductUsecase
  implements IProductUsecase<CancelTokenSource> {
  private productRepo: IProductRepository<CancelTokenSource>;

  constructor(productRepo: IProductRepository<CancelTokenSource>) {
    this.productRepo = productRepo;
  }

  async getProduct(
    pagination: IPagination,
    filter: PProductFilter,
    sorts: ISort<EProductSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IProduct[], IMeta]> {
    return await this.productRepo.getProduct(pagination, filter, sorts, signal);
  }

  async getProductById(id: number): Promise<IProduct> {
    return await this.productRepo.getProductById(id);
  }

  async getProductByCode(code: string): Promise<IProduct> {
    return await this.productRepo.getProductByCode(code);
  }

  async createProduct(
    product: PProduct,
    product_brand_id: number,
    product_category_id: number,
    display_image: any,
    detail_images: any[]
  ): Promise<void> {
    const formData = new FormData();
    formData.append("name", product.name);
    formData.append("slug", product.slug);
    formData.append("code", product.code);
    formData.append("color", product.color);
    if (product.description)
      formData.append("description", product.description);
    if (product.story) formData.append("story", product.story);
    formData.append("is_active", String(product.is_active));
    formData.append("gender", String(product.gender));
    if (product.release_date)
      formData.append(
        "release_date",
        moment(product.release_date).format("YYYY-MM-DD")
      );

    if (product.retail_price)
      formData.append("retail_price", String(product.retail_price));
    if (product.retail_price_currency)
      formData.append("retail_price_currency", product.retail_price_currency);
    formData.append("product_brand_id", String(product_brand_id));
    formData.append("product_category_id", String(product_category_id));
    formData.append("display_image", display_image);
    for (let image of detail_images) formData.append("detail_images[]", image);

    await this.productRepo.createProduct(formData);
  }

  async updateProduct(
    oldProduct: PProduct,
    newProduct: PProduct,
    product_brand_id: number,
    product_category_id: number,
    display_image: any,
    detail_images: any[],
    initial_detail_images: { image_path: string; deleted: boolean }[]
  ): Promise<void> {
    const formData = new FormData();
    if (oldProduct.name !== newProduct.name)
      formData.append("name", newProduct.name);
    if (oldProduct.slug !== newProduct.slug)
      formData.append("slug", newProduct.slug);
    if (oldProduct.code !== newProduct.code)
      formData.append("code", newProduct.code);
    formData.append("description", newProduct.description);
    formData.append("story", newProduct.story);
    formData.append("is_active", String(newProduct.is_active));
    formData.append("gender", String(newProduct.gender));
    formData.append("color", String(newProduct.color));
    if (newProduct.release_date)
      formData.append(
        "release_date",
        moment(newProduct.release_date).format("YYYY-MM-DD")
      );
    formData.append("retail_price", String(newProduct.retail_price));
    formData.append("retail_price_currency", newProduct.retail_price_currency);
    formData.append("product_brand_id", String(product_brand_id));
    formData.append("product_category_id", String(product_category_id));
    if (display_image) formData.append("new_display_image", display_image);
    if (detail_images) {
      for (let image of detail_images)
        formData.append("new_detail_images[]", image);
    }
    const deleted_detail_images = initial_detail_images
      .filter((img) => img.deleted)
      .map((img) => img.image_path)
      .join(",");
    if (Boolean(deleted_detail_images))
      formData.append("deleted_detail_images", deleted_detail_images);

    await this.productRepo.updateProduct(oldProduct.id, formData);
  }

  async deleteProduct(id: number): Promise<void> {
    await this.productRepo.deleteProduct(id);
  }
}
