import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import _ from "lodash";

import IPaymentConfirmationRepository from "../repository";
import { ICreateRequest, IValidateRequest } from "../request/request";

export default class PaymentConfirmationRepository
  implements IPaymentConfirmationRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private PAYMENT_CONFIRMATION_ROUTE = "/admin/payment-confirmation";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async createPaymentConfirmation(
    createRequest: ICreateRequest
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.PAYMENT_CONFIRMATION_ROUTE}`,
        createRequest
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async validatePaymentConfirmation(
    code: string,
    validateRequest: IValidateRequest
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.PAYMENT_CONFIRMATION_ROUTE}/validate/${code}`,
        validateRequest
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
