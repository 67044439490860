import { goPromise } from '@delanobgt/admin-core/util/helpers';
import { JWToken } from '@delanobgt/admin-core/util/types';
import { makeStyles } from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Interactors from 'src/core/Interactors';
import { RootState } from 'src/redux';
import { actions } from 'src/redux/auth/ducks';

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.5em 0.25em",
  },
  timeInfo: {
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
  timeText: {
    fontSize: "1rem",
  },
  expiredText: {
    backgroundColor: "orange",
    padding: "0.35rem 1rem",
    borderRadius: 8,
  },
}));

const JwtTimer = () => {
  const minTimeBeforeRefreshMs = (3 * 60 * 60 * 1000) - (5 * 60 * 1000);
  const token = useSelector<RootState, string>((state) => state.auth.token);
  const [timerMs, setTimerMs] = React.useState<number>(0);
  const [renewing, setRenewing] = React.useState<boolean>(false);
  const classes = useStyles({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!token) return;
    const decoded: JWToken = jwtDecode(token);

    const expiredTimeLeftMs = Math.max(
      0,
      decoded.exp * 1000 - minTimeBeforeRefreshMs - moment().valueOf()
    );
    const refreshTokenTimeout = setTimeout(async () => {
      let done = false;
      setRenewing(true);
      while (!done) {
        const [err, res] = await goPromise(Interactors.auth.refreshToken());
        if (!err) {
          done = true;
          dispatch(actions.setToken(res));
        }
      }
      setRenewing(false);
    }, expiredTimeLeftMs);

    const timerInterval = setInterval(() => {
      setTimerMs(decoded.exp * 1000 - moment().valueOf());
    }, 1000);

    return () => {
      clearTimeout(refreshTokenTimeout);
      clearInterval(timerInterval);
    };
  }, [token, minTimeBeforeRefreshMs, dispatch]);

  const hh = Math.floor(timerMs / (60 * 60 * 1000));
  const mm = Math.floor(Math.floor(timerMs % (60 * 60 * 1000)) / (60 * 1000));
  const ss = Math.floor(Math.floor(timerMs % (60 * 1000)) / 1000);
  const timeDisplay = `${String(hh).padStart(2, "0")} : ${String(mm).padStart(
    2,
    "0"
  )} : ${String(ss).padStart(2, "0")}`;

  // return (
  //   <div className={classes.root}>
  //     {timerMs <= 0 ? (
  //       <Typography variant="body1" className={classes.expiredText}>
  //         Session Expired
  //       </Typography>
  //     ) : (
  //       <div>
  //         <Typography
  //           align="center"
  //           variant="body2"
  //           className={classes.timeInfo}
  //         >
  //           Session Time <br />
  //           (Auto Renew when &lt; 15 min)
  //         </Typography>
  //         <Typography align="center" className={classes.timeText}>
  //           {timeDisplay}
  //         </Typography>
  //       </div>
  //     )}
  //     {renewing && <>&nbsp;</>}
  //   </div>
  // );
  return <div />;
};

export default JwtTimer;
