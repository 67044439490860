import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EPaymentConfirmationLegitCheckSortField,
  IPaymentConfirmationLegitCheck,
  PPaymentConfirmationLegitCheckFilter,
} from "../entities";
import IPaymentConfirmationLegitCheckRepository from "../repository";
import { IUpdateRequest } from "../request/request";
import IPaymentConfirmationLegitCheckUsecase from "../usecase";

export default class PaymentConfirmationLegitCheckUsecase
  implements IPaymentConfirmationLegitCheckUsecase<CancelTokenSource> {
  private paymentConfirmationLegitCheckRepo: IPaymentConfirmationLegitCheckRepository<
    CancelTokenSource
  >;

  constructor(
    paymentConfirmationLegitCheckRepo: IPaymentConfirmationLegitCheckRepository<
      CancelTokenSource
    >
  ) {
    this.paymentConfirmationLegitCheckRepo = paymentConfirmationLegitCheckRepo;
  }

  async getPaymentConfirmationLegitCheck(
    pagination: IPagination,
    filter: PPaymentConfirmationLegitCheckFilter,
    sorts: ISort<EPaymentConfirmationLegitCheckSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IPaymentConfirmationLegitCheck[], IMeta]> {
    return await this.paymentConfirmationLegitCheckRepo.getPaymentConfirmationLegitCheck(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getPaymentConfirmationLegitCheckByCode(
    code: string
  ): Promise<IPaymentConfirmationLegitCheck> {
    return await this.paymentConfirmationLegitCheckRepo.getPaymentConfirmationLegitCheckByCode(
      code
    );
  }

  async updatePaymentConfirmationLegitCheck(
    code: string,
    updateRequest: IUpdateRequest
  ): Promise<void> {
    await this.paymentConfirmationLegitCheckRepo.updatePaymentConfirmationLegitCheck(
      code,
      updateRequest
    );
  }
}
