import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { produce } from "immer";
import { Reducer } from "redux";
import {
  EAuthenticationSortField,
  EAuthenticationStatus,
  IAuthentication,
  PAuthenticationFilter,
} from "src/core/authentication/entities";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType,
} from "typesafe-actions";

/*
 * state
 */
export interface IAuthenticationState {
  authentications: IAuthentication[];
  loading: boolean;
  error: unknown;
  meta: IMeta;
  filter: PAuthenticationFilter;
  pagination: IPagination;
  sorts: ISort<EAuthenticationSortField>[];
  refreshDelay: number;
  whitelistedStatuses: EAuthenticationStatus[];
}

const INITIAL_STATE: IAuthenticationState = {
  authentications: [],
  loading: false,
  error: null,
  meta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  filter: {},
  pagination: {
    limit: 10,
    offset: 0,
  },
  sorts: [],
  refreshDelay: 5000,
  whitelistedStatuses: [],
};

/*
 * action types
 */
const FETCH_REQUESTED = "authentication/FETCH_REQUESTED";
const FETCH_SUCCEEDED = "authentication/FETCH_SUCCEEDED";
const FETCH_FAILED = "authentication/FETCH_FAILED";
const SET_FILTER_PAGINATION_SORT = "authentication/SET_FILTER_PAGINATION_SORT";
const SET_REFRESH_DELAY = "authentication/SET_REFRESH_DELAY";
const SET_WHITELISTED_STATUSES = "authentication/SET_WHITELISTED_STATUSES";
const POLLING_START_REQUESTED = "authentication/POLLING_START_REQUESTED";
const POLLING_STOP_REQUESTED = "authentication/POLLING_STOP_REQUESTED";

/*
 * action creators
 */
export const actions = {
  setFilterPaginationSort: createAction(SET_FILTER_PAGINATION_SORT)<{
    filter: PAuthenticationFilter;
    pagination: IPagination;
    sorts: ISort<EAuthenticationSortField>[];
  }>(),

  setRefreshDelay: createAction(SET_REFRESH_DELAY)<{
    refreshDelay: number;
  }>(),

  setWhitelistedStatuses: createAction(SET_WHITELISTED_STATUSES)<
    EAuthenticationStatus[]
  >(),

  fetchAsync: createAsyncAction(FETCH_REQUESTED, FETCH_SUCCEEDED, FETCH_FAILED)<
    unknown,
    {
      data: IAuthentication[];
      meta: IMeta;
    },
    unknown
  >(),

  pollingStartRequested: createAction(POLLING_START_REQUESTED)(),

  pollingStopRequested: createAction(POLLING_STOP_REQUESTED)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IAuthenticationState, TActions> = (
  state = INITIAL_STATE,
  action
) => {
  return produce<IAuthenticationState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.setFilterPaginationSort): {
        const { payload } = action;
        draft.filter = payload.filter;
        draft.pagination = payload.pagination;
        draft.sorts = payload.sorts;
        break;
      }
      case getType(actions.setRefreshDelay): {
        const { payload } = action;
        draft.refreshDelay = payload.refreshDelay;
        break;
      }
      case getType(actions.setWhitelistedStatuses): {
        const { payload } = action;
        draft.whitelistedStatuses = payload;
        break;
      }
      case getType(actions.fetchAsync.request): {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case getType(actions.fetchAsync.success): {
        const {
          payload: { data, meta },
        } = action;
        draft.authentications = data;
        draft.loading = false;
        draft.error = null;
        draft.meta = meta;
        break;
      }
      case getType(actions.fetchAsync.failure): {
        const { payload } = action;
        draft.loading = false;
        draft.error = payload;
        break;
      }
    }
  });
};

export default reducer;
