import IPaymentConfirmationRepository from "../repository";
import { ICreateRequest, IValidateRequest } from "../request/request";
import IPaymentConfirmationUsecase from "../usecase";

export default class PaymentConfirmationUsecase
  implements IPaymentConfirmationUsecase {
  private paymentConfirmationRepo: IPaymentConfirmationRepository;

  constructor(paymentConfirmationRepo: IPaymentConfirmationRepository) {
    this.paymentConfirmationRepo = paymentConfirmationRepo;
  }

  async createPaymentConfirmation(
    createRequest: ICreateRequest
  ): Promise<void> {
    await this.paymentConfirmationRepo.createPaymentConfirmation(createRequest);
  }

  async validatePaymentConfirmation(
    code: string,
    validateRequest: IValidateRequest
  ): Promise<void> {
    await this.paymentConfirmationRepo.validatePaymentConfirmation(
      code,
      validateRequest
    );
  }
}
