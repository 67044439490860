import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EInvoiceLegitCheckSortField,
  IInvoiceLegitCheck,
  PInvoiceLegitCheckFilter,
} from "../entities";
import IInvoiceLegitCheckRepository from "../repository";

export default class InvoiceLegitCheckRepository
  implements IInvoiceLegitCheckRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private ROUTE = "/admin/legit-check-invoice";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PInvoiceLegitCheckFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getInvoiceLegitCheck(
    pagination: IPagination,
    filter: PInvoiceLegitCheckFilter,
    sorts: ISort<EInvoiceLegitCheckSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IInvoiceLegitCheck[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getInvoiceLegitCheckByCode(code: string): Promise<IInvoiceLegitCheck> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.ROUTE}/${code}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }
}
