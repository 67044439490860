import { IRoute } from "@delanobgt/admin-core/util/types";
import {
  AccountBalance as AccountBalanceIcon,
  AttachMoney as AttachMoneyIcon,
  CardGiftcard as CardGiftcardIcon,
  Class as ClassIcon,
  Dashboard as DashboardIcon,
  ExitToApp as ExitToAppIcon,
  NotificationsActive as NotificationIcon,
  Payment as PaymentIcon,
  Receipt as ReceiptIcon,
  Store as StoreIcon,
  Style as StyleIcon,
  VerifiedUser as AuthenticationIcon,
} from "@material-ui/icons";
import { lazy } from "react";

const Authentication = lazy(() =>
  import("src/pages/Authentication/Authentication")
);
const Bank = lazy(() => import("src/pages/Bank/Bank"));
const Consignment = lazy(() => import("src/pages/Consignment/Consignment"));
const CurrencyRate = lazy(() => import("src/pages/CurrencyRate/CurrencyRate"));
const Dashboard = lazy(() => import("src/pages/Dashboard/Dashboard"));
const Expense = lazy(() => import("src/pages/Expense/Expense"));
const GuideDetail = lazy(() => import("src/pages/GuideDetail/GuideDetail"));
const GuideDetailType = lazy(() =>
  import("src/pages/GuideDetailType/GuideDetailType")
);
const InvoiceLegitCheck = lazy(() =>
  import("src/pages/InvoiceLegitCheck/InvoiceLegitCheck")
);
const InvoicePackage = lazy(() =>
  import("src/pages/InvoicePackage/InvoicePackage")
);
const LegitCheckBrand = lazy(() =>
  import("src/pages/LegitCheckBrand/LegitCheckBrand")
);
const LegitCheckType = lazy(() =>
  import("src/pages/LegitCheckType/LegitCheckType")
);
const Notification = lazy(() => import("src/pages/Notification/Notification"));
const PackageCouponEffect = lazy(() =>
  import("src/pages/PackageCouponEffect/PackageCouponEffect")
);
const PackageList = lazy(() => import("src/pages/PackageList/PackageList"));
const PackageSet = lazy(() => import("src/pages/PackageSet/PackageSet"));
const PaymentConfirmationCreate = lazy(() =>
  import("src/pages/PaymentConfirmationCreate/PaymentConfirmationCreate")
);
const PaymentConfirmationLegitCheck = lazy(() =>
  import(
    "src/pages/PaymentConfirmationLegitCheck/PaymentConfirmationLegitCheck"
  )
);
const PaymentConfirmationPackage = lazy(() =>
  import("src/pages/PaymentConfirmationPackage/PaymentConfirmationPackage")
);
const Revenue = lazy(() => import("src/pages/Revenue/Revenue"));
const Logout = lazy(() => import("src/pages/Auth/Logout/Logout"));

export type TAdminRouteNames =
  | "AUTHENTICATION"
  | "BANK"
  | "CONSIGNMENT"
  | "CURRENCY_RATE"
  | "DASHBOARD"
  | "EXPENSE"
  | "GUIDE_DETAIL"
  | "GUIDE_DETAIL_TYPE"
  | "INVOICE_LEGIT_CHECK"
  | "INVOICE_PACKAGE"
  | "LEGIT_CHECK_BRAND"
  | "LEGIT_CHECK_TYPE"
  | "NOTIFICATION"
  | "PACKAGE_EFFECT_COUPON"
  | "PACKAGE_LIST"
  | "PACKAGE_SET"
  | "PAYMENT_CONFIRMATION_CREATE"
  | "PAYMENT_CONFIRMATION_LEGIT_CHECK"
  | "PAYMENT_CONFIRMATION_PACKAGE"
  | "REVENUE"
  | "LOGOUT";

export const adminRoutes: Record<TAdminRouteNames, IRoute> = {
  AUTHENTICATION: {
    Icon: AuthenticationIcon,
    path: "/authentication",
    Page: Authentication,
  },
  BANK: {
    Icon: AccountBalanceIcon,
    path: "/bank",
    Page: Bank,
  },
  CONSIGNMENT: {
    Icon: StoreIcon,
    path: "/consignment",
    Page: Consignment,
  },
  CURRENCY_RATE: {
    Icon: AttachMoneyIcon,
    path: "/currency-rate",
    Page: CurrencyRate,
  },
  DASHBOARD: {
    Icon: DashboardIcon,
    path: "/dashboard",
    Page: Dashboard,
  },
  EXPENSE: {
    Icon: AttachMoneyIcon,
    path: "/expense",
    Page: Expense,
  },
  GUIDE_DETAIL: {
    Icon: ClassIcon,
    path: "/guide-detail",
    Page: GuideDetail,
  },
  GUIDE_DETAIL_TYPE: {
    Icon: ClassIcon,
    path: "/guide-detail-type",
    Page: GuideDetailType,
  },
  INVOICE_LEGIT_CHECK: {
    Icon: ReceiptIcon,
    path: "/invoice-legit-check",
    Page: InvoiceLegitCheck,
  },
  INVOICE_PACKAGE: {
    Icon: ReceiptIcon,
    path: "/invoice-package",
    Page: InvoicePackage,
  },
  LEGIT_CHECK_BRAND: {
    Icon: StyleIcon,
    path: "/legit-check-brand",
    Page: LegitCheckBrand,
  },
  LEGIT_CHECK_TYPE: {
    Icon: StyleIcon,
    path: "/legit-check-type",
    Page: LegitCheckType,
  },
  NOTIFICATION: {
    Icon: NotificationIcon,
    path: "/notification",
    Page: Notification,
  },
  PACKAGE_EFFECT_COUPON: {
    Icon: CardGiftcardIcon,
    path: "/package-effect-coupon",
    Page: PackageCouponEffect,
  },
  PACKAGE_LIST: {
    Icon: CardGiftcardIcon,
    path: "/package-list",
    Page: PackageList,
  },
  PACKAGE_SET: {
    Icon: CardGiftcardIcon,
    path: "/package-set",
    Page: PackageSet,
  },
  PAYMENT_CONFIRMATION_CREATE: {
    Icon: PaymentIcon,
    path: "/payment-confirmation-create",
    Page: PaymentConfirmationCreate,
  },
  PAYMENT_CONFIRMATION_LEGIT_CHECK: {
    Icon: PaymentIcon,
    path: "/payment-confirmation-legit-check",
    Page: PaymentConfirmationLegitCheck,
  },
  PAYMENT_CONFIRMATION_PACKAGE: {
    Icon: PaymentIcon,
    path: "/payment-confirmation-package",
    Page: PaymentConfirmationPackage,
  },
  REVENUE: {
    Icon: AttachMoneyIcon,
    path: "/revenue",
    Page: Revenue,
  },
  LOGOUT: {
    Icon: ExitToAppIcon,
    path: "/logout",
    Page: Logout,
  },
};
