import { ILegitCheckDetail, PLegitCheckDetail } from "../entities";
import ILegitCheckRepository from "../repository";
import ILegitCheckUsecase from "../usecase";

export default class LegitCheckUsecase implements ILegitCheckUsecase {
  private legitCheckRepo: ILegitCheckRepository;

  constructor(legitCheckRepo: ILegitCheckRepository) {
    this.legitCheckRepo = legitCheckRepo;
  }

  async getLegitCheckDetails(
    authentication_id: number
  ): Promise<ILegitCheckDetail[]> {
    return await this.legitCheckRepo.getLegitCheckDetails(authentication_id);
  }

  async getLegitCheckDetailById(id: number): Promise<ILegitCheckDetail> {
    return await this.legitCheckRepo.getLegitCheckDetailById(id);
  }

  async createLegitCheckDetail(
    legitCheckDetail: PLegitCheckDetail
  ): Promise<void> {
    legitCheckDetail.price = Number(legitCheckDetail.price);
    return await this.legitCheckRepo.createLegitCheckDetail(legitCheckDetail);
  }

  async updateLegitCheckDetail(id: number, price: number): Promise<void> {
    return await this.legitCheckRepo.updateLegitCheckDetail(id, price);
  }
}
