import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EProductBrandSortField,
  IProductBrand,
  PProductBrand,
  PProductBrandFilter,
} from "../entities";
import IProductBrandRepository from "../repository";
import IProductBrandUsecase from "../usecase";

export default class ProductBrandUsecase
  implements IProductBrandUsecase<CancelTokenSource> {
  private productBrandRepo: IProductBrandRepository<CancelTokenSource>;

  constructor(productBrandRepo: IProductBrandRepository<CancelTokenSource>) {
    this.productBrandRepo = productBrandRepo;
  }

  async getProductBrand(
    pagination: IPagination,
    filter: PProductBrandFilter,
    sorts: ISort<EProductBrandSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IProductBrand[], IMeta]> {
    return await this.productBrandRepo.getProductBrand(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getProductBrandById(id: number): Promise<IProductBrand> {
    return await this.productBrandRepo.getProductBrandById(id);
  }

  async createProductBrand(
    productBrand: PProductBrand,
    image?: any
  ): Promise<void> {
    const formData = new FormData();
    formData.append("name", productBrand.name);
    formData.append("slug", productBrand.slug);
    formData.append("parent_id", String(productBrand.parent_id));
    if (image) formData.append("logo_image", image);

    await this.productBrandRepo.createProductBrand(formData);
  }

  async updateProductBrand(
    oldProductBrand: PProductBrand,
    newProductBrand: PProductBrand,
    image?: any
  ): Promise<void> {
    const formData = new FormData();
    formData.append("name", newProductBrand.name);
    if (oldProductBrand.slug !== newProductBrand.slug)
      formData.append("slug", newProductBrand.slug);
    formData.append("parent_id", String(newProductBrand.parent_id));
    formData.append("is_active", String(newProductBrand.is_active));
    if (image) formData.append("logo_image", image);

    await this.productBrandRepo.updateProductBrand(
      oldProductBrand.id,
      formData
    );
  }
}
