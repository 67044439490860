import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EInvoicePackageSortField,
  IInvoicePackage,
  PInvoicePackageFilter,
} from "../entities";
import IInvoicePackageRepository from "../repository";

export default class InvoicePackageRepository
  implements IInvoicePackageRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private BANK_ROUTE = "/admin/package-invoice";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PInvoicePackageFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getInvoicePackage(
    pagination: IPagination,
    filter: PInvoicePackageFilter,
    sorts: ISort<EInvoicePackageSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IInvoicePackage[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.BANK_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getInvoicePackageByCode(code: string): Promise<IInvoicePackage> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.BANK_ROUTE}/${code}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }
}
