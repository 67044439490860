import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import shallowDiff from "shallow-diff";

import { EBankSortField, IBank, PBank, PBankFilter } from "../entities";
import IBankRepository from "../repository";
import IBankUsecase from "../usecase";

export default class BankUsecase implements IBankUsecase<CancelTokenSource> {
  private bankRepo: IBankRepository<CancelTokenSource>;

  constructor(bankRepo: IBankRepository<CancelTokenSource>) {
    this.bankRepo = bankRepo;
  }

  async getBank(
    pagination: IPagination,
    filter: PBankFilter,
    sorts: ISort<EBankSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IBank[], IMeta]> {
    return await this.bankRepo.getBank(pagination, filter, sorts, signal);
  }

  async getBankById(id: number): Promise<IBank> {
    return await this.bankRepo.getBankById(id);
  }

  async createBank(bank: PBank): Promise<void> {
    await this.bankRepo.createBank(bank);
  }

  async updateBank(oldBank: PBank, newBank: PBank): Promise<void> {
    const diffBank = _.pick(
      newBank,
      shallowDiff(oldBank, newBank).updated
    ) as PBank;

    await this.bankRepo.updateBank(oldBank.id, diffBank);
  }
}
