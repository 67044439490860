import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import { EExpenseSortField, IExpense, PExpenseFilter } from "../entities";
import IExpenseRepository from "../repository";
import IExpenseUsecase from "../usecase";

export default class ExpenseUsecase
  implements IExpenseUsecase<CancelTokenSource> {
  private expenseRepo: IExpenseRepository<CancelTokenSource>;

  constructor(expenseRepo: IExpenseRepository<CancelTokenSource>) {
    this.expenseRepo = expenseRepo;
  }

  async getExpense(
    pagination: IPagination,
    filter: PExpenseFilter,
    sorts: ISort<EExpenseSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IExpense[], IMeta]> {
    return await this.expenseRepo.getExpense(pagination, filter, sorts, signal);
  }

  async getExpenseById(id: number): Promise<IExpense> {
    return await this.expenseRepo.getExpenseById(id);
  }

  async getExpenseExcelData(month: string, year: string): Promise<any> {
    return await this.expenseRepo.getExpenseExcelData(month, year);
  }
}
