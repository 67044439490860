import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EInvoiceLegitCheckSortField,
  IInvoiceLegitCheck,
  PInvoiceLegitCheckFilter,
} from "../entities";
import IInvoiceLegitCheckRepository from "../repository";
import IInvoiceLegitCheckUsecase from "../usecase";

export default class InvoiceLegitCheckUsecase
  implements IInvoiceLegitCheckUsecase<CancelTokenSource> {
  private bankRepo: IInvoiceLegitCheckRepository<CancelTokenSource>;

  constructor(bankRepo: IInvoiceLegitCheckRepository<CancelTokenSource>) {
    this.bankRepo = bankRepo;
  }

  async getInvoiceLegitCheck(
    pagination: IPagination,
    filter: PInvoiceLegitCheckFilter,
    sorts: ISort<EInvoiceLegitCheckSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IInvoiceLegitCheck[], IMeta]> {
    return await this.bankRepo.getInvoiceLegitCheck(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getInvoiceLegitCheckByCode(code: string): Promise<IInvoiceLegitCheck> {
    return await this.bankRepo.getInvoiceLegitCheckByCode(code);
  }
}
