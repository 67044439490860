import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EInvoicePackageSortField,
  IInvoicePackage,
  PInvoicePackageFilter,
} from "../entities";
import IInvoicePackageRepository from "../repository";
import IInvoicePackageUsecase from "../usecase";

export default class InvoicePackageUsecase
  implements IInvoicePackageUsecase<CancelTokenSource> {
  private bankRepo: IInvoicePackageRepository<CancelTokenSource>;

  constructor(bankRepo: IInvoicePackageRepository<CancelTokenSource>) {
    this.bankRepo = bankRepo;
  }

  async getInvoicePackage(
    pagination: IPagination,
    filter: PInvoicePackageFilter,
    sorts: ISort<EInvoicePackageSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IInvoicePackage[], IMeta]> {
    return await this.bankRepo.getInvoicePackage(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getInvoicePackageByCode(code: string): Promise<IInvoicePackage> {
    return await this.bankRepo.getInvoicePackageByCode(code);
  }
}
