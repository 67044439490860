import INotificationRepository from "../repository";
import INotificationUsecase from "../usecase";
import { ISendRequest } from "../request/request";

export default class NotificationUsecase implements INotificationUsecase {
  private notificationRepo: INotificationRepository;

  constructor(notificationRepo: INotificationRepository) {
    this.notificationRepo = notificationRepo;
  }

  async sendPersonalNotification(
    user_id: number,
    sendRequest: ISendRequest
  ): Promise<void> {
    const formData = new FormData();
    formData.append("user_id", user_id.toString());
    formData.append("user_role", "user");
    formData.append("subject", sendRequest.subject);
    formData.append("description", sendRequest.description);
    formData.append("logo", sendRequest.logo as Blob);
    await this.notificationRepo.sendPersonalNotification(formData);
  }

  async sendGlobalNotification(sendRequest: ISendRequest): Promise<void> {
    const formData = new FormData();
    formData.append("subject", sendRequest.subject);
    formData.append("description", sendRequest.description);
    if (sendRequest.logo) {
      formData.append("logo", sendRequest.logo as Blob);
    }
    await this.notificationRepo.sendGlobalNotification(formData);
  }
}
