import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EGuideDetailTypeSortField,
  IGuideDetailType,
  PGuideDetailType,
  PGuideDetailTypeFilter,
} from "../entities";
import IGuideDetailTypeRepository from "../repository";

export default class GuideDetailTypeRepository
  implements IGuideDetailTypeRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private GUIDE_DETAIL_TYPE_ROUTE = "/admin/guide-detail-type";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PGuideDetailTypeFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getGuideDetailType(
    pagination: IPagination,
    filter: PGuideDetailTypeFilter,
    sorts: ISort<EGuideDetailTypeSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IGuideDetailType[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.GUIDE_DETAIL_TYPE_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getGuideDetailTypeById(id: number): Promise<IGuideDetailType> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.GUIDE_DETAIL_TYPE_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async createGuideDetailType(
    guideDetailType: PGuideDetailType
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        this.GUIDE_DETAIL_TYPE_ROUTE,
        guideDetailType
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateGuideDetailType(
    id: number,
    guideDetailType: PGuideDetailType
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.GUIDE_DETAIL_TYPE_ROUTE}/${id}`,
        guideDetailType
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
