import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import _ from "lodash";

import INotificationRepository from "../repository";

export default class NotificationRepository implements INotificationRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private NOTIFICATION_ROUTE = "/admin/notification";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async sendPersonalNotification(formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.NOTIFICATION_ROUTE}/send`, formData)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async sendGlobalNotification(formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.NOTIFICATION_ROUTE}/broadcast/global`,
        formData
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
