import { adminRoutes } from "src/Router/router/routes/loggedInRoutes/AdminRoutes";

import { IMenu } from "./types";

export const menuList: IMenu[] = [
  {
    title: "Dashboard",
    Icon: adminRoutes.DASHBOARD.Icon,
    link: adminRoutes.DASHBOARD.path,
  },
  {
    title: "Authentication",
    Icon: adminRoutes.AUTHENTICATION.Icon,
    subMenus: [
      {
        title: "On Progress",
        Icon: adminRoutes.AUTHENTICATION.Icon,
        link: `${adminRoutes.AUTHENTICATION.path}/on-progress`,
      },
      {
        title: "On Hold",
        Icon: adminRoutes.AUTHENTICATION.Icon,
        link: `${adminRoutes.AUTHENTICATION.path}/on-hold`,
      },
      {
        title: "Done",
        Icon: adminRoutes.AUTHENTICATION.Icon,
        link: `${adminRoutes.AUTHENTICATION.path}/done`,
      },
    ],
  },
  {
    title: "Consignment",
    Icon: adminRoutes.CONSIGNMENT.Icon,
    link: process.env.REACT_APP_WHISTLER_CONSIGNMENT_URL,
    target: "_blank",
  },
  {
    title: "Notification",
    Icon: adminRoutes.NOTIFICATION.Icon,
    link: process.env.REACT_APP_WHISTLER_NOTIFICATION_URL,
    target: "_blank",
  },
  {
    title: "Revenue & Expense",
    Icon: adminRoutes.REVENUE.Icon,
    subMenus: [
      {
        title: "Revenue",
        Icon: adminRoutes.REVENUE.Icon,
        link: adminRoutes.REVENUE.path,
      },
      {
        title: "Expense",
        Icon: adminRoutes.EXPENSE.Icon,
        link: adminRoutes.EXPENSE.path,
      },
      {
        title: "Currency Rate",
        Icon: adminRoutes.CURRENCY_RATE.Icon,
        link: adminRoutes.CURRENCY_RATE.path,
      },
    ],
  },
  {
    title: "Invoice",
    Icon: adminRoutes.INVOICE_LEGIT_CHECK.Icon,
    subMenus: [
      {
        title: "Legit Check",
        Icon: adminRoutes.INVOICE_LEGIT_CHECK.Icon,
        link: adminRoutes.INVOICE_LEGIT_CHECK.path,
      },
      {
        title: "Package",
        Icon: adminRoutes.INVOICE_PACKAGE.Icon,
        link: adminRoutes.INVOICE_PACKAGE.path,
      },
    ],
  },
  {
    title: "Payment Confirmation",
    Icon: adminRoutes.PAYMENT_CONFIRMATION_PACKAGE.Icon,
    subMenus: [
      {
        title: "Create New",
        Icon: adminRoutes.PAYMENT_CONFIRMATION_CREATE.Icon,
        link: adminRoutes.PAYMENT_CONFIRMATION_CREATE.path,
      },
      {
        title: "Legit Check",
        Icon: adminRoutes.PAYMENT_CONFIRMATION_LEGIT_CHECK.Icon,
        link: adminRoutes.PAYMENT_CONFIRMATION_LEGIT_CHECK.path,
      },
      {
        title: "Package",
        Icon: adminRoutes.PAYMENT_CONFIRMATION_PACKAGE.Icon,
        link: adminRoutes.PAYMENT_CONFIRMATION_PACKAGE.path,
      },
    ],
  },
  {
    title: "Guide",
    Icon: adminRoutes.GUIDE_DETAIL.Icon,
    subMenus: [
      {
        title: "Guides",
        Icon: adminRoutes.GUIDE_DETAIL.Icon,
        link: adminRoutes.GUIDE_DETAIL.path,
      },
      {
        title: "Guide Type",
        Icon: adminRoutes.GUIDE_DETAIL_TYPE.Icon,
        link: adminRoutes.GUIDE_DETAIL_TYPE.path,
      },
    ],
  },
  {
    title: "Package",
    Icon: adminRoutes.PACKAGE_LIST.Icon,
    subMenus: [
      {
        title: "Package Coupon Effect",
        Icon: adminRoutes.PACKAGE_EFFECT_COUPON.Icon,
        link: adminRoutes.PACKAGE_EFFECT_COUPON.path,
      },
      {
        title: "Package List",
        Icon: adminRoutes.PACKAGE_LIST.Icon,
        link: process.env.REACT_APP_WHISTLER_PACKAGE_LIST_URL,
        target: "_blank",
      },
      {
        title: "Package Set",
        Icon: adminRoutes.PACKAGE_SET.Icon,
        link: adminRoutes.PACKAGE_SET.path,
      },
    ],
  },
  {
    title: "LC Product",
    Icon: adminRoutes.LEGIT_CHECK_BRAND.Icon,
    subMenus: [
      {
        title: "Brand",
        Icon: adminRoutes.LEGIT_CHECK_BRAND.Icon,
        link: process.env.REACT_APP_WHISTLER_LEGIT_CHECK_BRAND_URL,
        target: "_blank",
      },
      {
        title: "Type",
        Icon: adminRoutes.LEGIT_CHECK_TYPE.Icon,
        link: process.env.REACT_APP_WHISTLER_LEGIT_CHECK_TYPE_URL,
        target: "_blank",
      },
    ],
  },
  {
    title: "Depatu Bank",
    Icon: adminRoutes.BANK.Icon,
    link: adminRoutes.BANK.path,
  },
];
