import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EConsignmentSortField,
  IConsignment,
  PConsignmentFilter,
} from "../entities";
import IConsignmentRepository from "../repository";
import IConsignmentUsecase from "../usecase";

export default class ConsignmentUsecase
  implements IConsignmentUsecase<CancelTokenSource> {
  private consignmentRepo: IConsignmentRepository<CancelTokenSource>;

  constructor(consignmentRepo: IConsignmentRepository<CancelTokenSource>) {
    this.consignmentRepo = consignmentRepo;
  }

  async getConsignment(
    pagination: IPagination,
    filter: PConsignmentFilter,
    sorts: ISort<EConsignmentSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IConsignment[], IMeta]> {
    return await this.consignmentRepo.getConsignment(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getConsignmentById(id: number): Promise<IConsignment> {
    return await this.consignmentRepo.getConsignmentById(id);
  }

  async approveConsignment(id: number): Promise<void> {
    await this.consignmentRepo.approveConsignment(id);
  }

  async rejectConsignment(id: number, rejected_reason: string): Promise<void> {
    await this.consignmentRepo.rejectConsignment(id, rejected_reason);
  }
}
