import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  ELegitCheckBrandSortField,
  ILegitCheckBrand,
  PLegitCheckBrandFilter,
} from "../entities";
import ILegitCheckBrandRepository from "../repository";
import ILegitCheckBrandUpdateRequest from "../request/request";
import ILegitCheckBrandUsecase from "../usecase";

export default class LegitCheckBrandUsecase
  implements ILegitCheckBrandUsecase<CancelTokenSource> {
  private legitCheckBrandRepo: ILegitCheckBrandRepository<CancelTokenSource>;

  constructor(
    legitCheckBrandRepo: ILegitCheckBrandRepository<CancelTokenSource>
  ) {
    this.legitCheckBrandRepo = legitCheckBrandRepo;
  }

  async getLegitCheckBrand(
    pagination: IPagination,
    filter: PLegitCheckBrandFilter,
    sorts: ISort<ELegitCheckBrandSortField>[],
    signal?: CancelTokenSource
  ): Promise<[ILegitCheckBrand[], IMeta]> {
    return await this.legitCheckBrandRepo.getLegitCheckBrand(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getLegitCheckBrandById(id: number): Promise<ILegitCheckBrand> {
    return await this.legitCheckBrandRepo.getLegitCheckBrandById(id);
  }

  async updateLegitCheckBrand(
    id: number,
    updateRequest: ILegitCheckBrandUpdateRequest
  ): Promise<void> {
    await this.legitCheckBrandRepo.updateLegitCheckBrand(id, updateRequest);
  }

  async manualUpdateAllLegitCheckBrand(): Promise<void> {
    await this.legitCheckBrandRepo.manualUpdateAllLegitCheckBrand();
  }
}
