import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EAuthenticationSortField,
  EAuthenticationStatus,
  IAuthentication,
  PAuthenticationFilter,
} from "../entities";
import IAuthenticationRepository from "../repository";
import { IReleaseResultRequest, IUpdateRequest } from "../request/request";

export default class AuthenticationRepository
  implements IAuthenticationRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private AUTHENTICATION_ROUTE = "/admin/authentication";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PAuthenticationFilter) => {
    const created_at = filter.created_at_enabled
      ? `${filter.created_at_start},${filter.created_at_end}`
      : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.created_at_enabled;
    delete clonedFilter.created_at_start;
    delete clonedFilter.created_at_end;

    return _.pickBy(
      {
        ...clonedFilter,
        created_at,
      },
      Boolean
    );
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getAuthentication(
    pagination: IPagination,
    filter: PAuthenticationFilter,
    sorts: ISort<EAuthenticationSortField>[],
    whitelistedStatuses: EAuthenticationStatus[],
    signal?: CancelTokenSource
  ): Promise<[IAuthentication[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const statusParams = whitelistedStatuses
      .map((s) => `&status=${s}`)
      .join("");
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.AUTHENTICATION_ROUTE}?sort=${sort}${
          filter.status ? "" : statusParams
        }`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getAuthenticationByCode(code: string): Promise<IAuthentication> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.AUTHENTICATION_ROUTE}/${code}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async updateAuthentication(
    code: string,
    updateRequest: IUpdateRequest
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.AUTHENTICATION_ROUTE}/${code}`,
        updateRequest
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async authenticatingAuthentication(code: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/${code}/authenticating`
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async addComment(code: string, message: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/${code}/comment`,
        { message }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async addGuideDetail(code: string, guide_detail_id: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/${code}/guide-detail/add`,
        { guide_detail_id }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async deleteGuideDetail(
    code: string,
    deleted_guide_detail_id: string
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/${code}/guide-detail/delete`,
        { deleted_guide_detail_id }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async needAdditionalPicture(
    code: string,
    add_picture_note: string
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/${code}/need-additional-picture`,
        { add_picture_note }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async sendNeedAdditionalPictureNotification(code: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/${code}/need-additional-picture/notification`
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async sendNeedAdditionalPictureNotificationGlobal(): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/notification/need-additional-picture`
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async cancelNeedAdditionalPicture(
    code: string,
    password: string
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/${code}/need-additional-picture/cancel`,
        { password }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async releaseResult(
    code: string,
    update: IReleaseResultRequest
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/${code}/result`,
        update
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async resetResult(code: string, password: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/${code}/result/reset`,
        { password }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async restoreBackup(code: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.AUTHENTICATION_ROUTE}/${code}/restore-picture`
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
