import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  ECurrencyRateSortField,
  ICurrencyRate,
  PCurrencyRate,
  PCurrencyRateFilter,
} from "../entities";
import ICurrencyRateRepository from "../repository";
import { ICurrencyUpdateRequest } from "../request/request";
import ICurrencyRateUsecase from "../usecase";

export default class CurrencyRateUsecase
  implements ICurrencyRateUsecase<CancelTokenSource> {
  private currencyRateRepo: ICurrencyRateRepository<CancelTokenSource>;

  constructor(currencyRateRepo: ICurrencyRateRepository<CancelTokenSource>) {
    this.currencyRateRepo = currencyRateRepo;
  }

  async getCurrencyRate(
    pagination: IPagination,
    filter: PCurrencyRateFilter,
    sorts: ISort<ECurrencyRateSortField>[],
    signal?: CancelTokenSource
  ): Promise<[ICurrencyRate[], IMeta]> {
    return await this.currencyRateRepo.getCurrencyRate(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getCurrencyRateById(id: number): Promise<ICurrencyRate> {
    return await this.currencyRateRepo.getCurrencyRateById(id);
  }

  async createCurrencyRate(currencyRate: PCurrencyRate): Promise<void> {
    await this.currencyRateRepo.createCurrencyRate(currencyRate);
  }

  async updateCurrencyRate(
    id: number,
    updateRequest: ICurrencyUpdateRequest
  ): Promise<void> {
    await this.currencyRateRepo.updateCurrencyRate(id, updateRequest);
  }
}
