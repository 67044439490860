import { Divider, Drawer, IconButton, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Menu as MenuIcon } from "@material-ui/icons";
import React from "react";
import JwtTimer from "src/components/misc/JwtTimer";

import MenuList from "./myDrawer/MenuList";

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    boxSizing: "border-box",
    width: (props) => props.drawerWidth,
    flexShrink: 0,
    zIndex: 5,
  },
  drawerPaper: {
    boxSizing: "border-box",
    width: (props) => props.drawerWidth,
  },
  drawerHeadline: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 0",
  },
  bigText: {
    padding: "1rem",
    flex: 1,
  },
}));

interface IProps {
  drawerOpen: boolean;
  drawerWidth: number;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TemporaryDrawer(props: IProps) {
  const classes = useStyles(props);

  const { drawerOpen, setDrawerOpen } = props;

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      open={drawerOpen}
      keepMounted
      onClose={() => setDrawerOpen(false)}
    >
      <div
        className={classes.drawerHeadline}
        onKeyDown={() => setDrawerOpen(false)}
      >
        <IconButton
          onClick={() => setDrawerOpen(false)}
          style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
        >
          <MenuIcon />
        </IconButton>
        <img
          alt=""
          src={require("src/assets/images/icons/depatu.png")}
          style={{ height: 25, marginRight: "0.5rem" }}
        />
        <Typography variant="h6" color="inherit" noWrap>
          Legit Check
        </Typography>
      </div>
      <Divider />
      <JwtTimer />
      <Divider />
      <MenuList setDrawerOpen={setDrawerOpen} />
    </Drawer>
  );
}
