import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EPackageListSortField,
  IPackageList,
  PPackageListFilter,
} from "../entities";
import IPackageListRepository from "../repository";
import {
  IPackageListCreateUpdateRequest,
  IPackageListSendRequest,
} from "../request/request";
import IPackageListUsecase from "../usecase";

export default class PackageListUsecase
  implements IPackageListUsecase<CancelTokenSource> {
  private packageListRepo: IPackageListRepository<CancelTokenSource>;

  constructor(packageListRepo: IPackageListRepository<CancelTokenSource>) {
    this.packageListRepo = packageListRepo;
  }

  async getPackageList(
    pagination: IPagination,
    filter: PPackageListFilter,
    sorts: ISort<EPackageListSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IPackageList[], IMeta]> {
    return await this.packageListRepo.getPackageList(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getPackageListById(id: number): Promise<IPackageList> {
    return await this.packageListRepo.getPackageListById(id);
  }

  async createPackageList(
    createRequest: IPackageListCreateUpdateRequest
  ): Promise<void> {
    const formData = new FormData();
    formData.append("name", createRequest.name);
    formData.append("description", createRequest.description);
    formData.append("real_price", String(createRequest.real_price));
    formData.append("buy_price", String(createRequest.buy_price));
    formData.append("available_for", String(createRequest.available_for));
    formData.append(
      "is_available_to_buy",
      String(createRequest.is_available_to_buy)
    );
    formData.append("image", createRequest.image);
    for (let couponEffect of createRequest.list_coupon_effects) {
      formData.append("list_coupon_effects", JSON.stringify(couponEffect));
    }
    await this.packageListRepo.createPackageList(formData);
  }

  async updatePackageList(
    id: number,
    updateRequest: IPackageListCreateUpdateRequest
  ): Promise<void> {
    const formData = new FormData();
    formData.append("name", updateRequest.name);
    formData.append("description", updateRequest.description);
    formData.append("real_price", String(updateRequest.real_price));
    formData.append("buy_price", String(updateRequest.buy_price));
    formData.append("available_for", String(updateRequest.available_for));
    formData.append(
      "is_available_to_buy",
      String(updateRequest.is_available_to_buy)
    );
    if (updateRequest.image) formData.append("new_image", updateRequest.image);
    for (let couponEffect of updateRequest.list_coupon_effects) {
      formData.append("list_coupon_effects", JSON.stringify(couponEffect));
    }
    await this.packageListRepo.updatePackageList(id, formData);
  }

  async sendPackageList(
    id: number,
    sendRequest: IPackageListSendRequest
  ): Promise<void> {
    await this.packageListRepo.sendPackageList(id, sendRequest);
  }
}
