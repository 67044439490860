import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { produce } from "immer";
import { Reducer } from "redux";
import {
  ELegitCheckBrandSortField,
  ILegitCheckBrand,
  PLegitCheckBrandFilter,
} from "src/core/legitCheckBrand/entities";
import { ActionType, createAction, getType } from "typesafe-actions";

/*
 * state
 */
export interface ILegitCheckBrandState {
  legitCheckBrands: ILegitCheckBrand[];
  loading: boolean;
  error: any;
  meta: IMeta;
}

const initialState: ILegitCheckBrandState = {
  legitCheckBrands: [],
  loading: false,
  error: null,
  meta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
};

/*
 * action types
 */
const FETCH_REQUESTED = "legit-check-brand/FETCH_REQUESTED";
const FETCH_SUCCEEDED = "legit-check-brand/FETCH_SUCCEEDED";
const FETCH_FAILED = "legit-check-brand/FETCH_FAILED";
const POLLING_START_REQUESTED = "legit-check-brand/POLLING_START_REQUESTED";
const POLLING_STOP_REQUESTED = "legit-check-brand/POLLING_STOP_REQUESTED";

/*
 * action creators
 */
export const actions = {
  fetchRequested: createAction(FETCH_REQUESTED)(),

  fetchSucceeded: createAction(FETCH_SUCCEEDED)<{
    data: ILegitCheckBrand[];
    meta: IMeta;
  }>(),

  fetchFailed: createAction(FETCH_FAILED)<any>(),

  pollingStartRequested: createAction(POLLING_START_REQUESTED)<{
    filter: PLegitCheckBrandFilter;
    pagination: IPagination;
    sorts: ISort<ELegitCheckBrandSortField>[];
    refreshDelay: number;
  }>(),

  pollingStopRequested: createAction(POLLING_STOP_REQUESTED)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<ILegitCheckBrandState, TActions> = (
  state = initialState,
  action
) => {
  return produce<ILegitCheckBrandState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.fetchRequested): {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case getType(actions.fetchSucceeded): {
        const {
          payload: { data, meta },
        } = action;
        draft.legitCheckBrands = data;
        draft.loading = false;
        draft.error = null;
        draft.meta = meta;
        break;
      }
      case getType(actions.fetchFailed): {
        const { payload } = action;
        draft.loading = false;
        draft.error = payload;
        break;
      }
    }
  });
};

export default reducer;
