import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import shallowDiff from "shallow-diff";

import {
  ELegitCheckTypeSortField,
  ILegitCheckType,
  PLegitCheckType,
  PLegitCheckTypeFilter,
} from "../entities";
import ILegitCheckTypeRepository from "../repository";
import ILegitCheckTypeUsecase from "../usecase";

export default class LegitCheckTypeUsecase
  implements ILegitCheckTypeUsecase<CancelTokenSource> {
  private legitCheckTypeRepo: ILegitCheckTypeRepository<CancelTokenSource>;

  constructor(
    legitCheckTypeRepo: ILegitCheckTypeRepository<CancelTokenSource>
  ) {
    this.legitCheckTypeRepo = legitCheckTypeRepo;
  }

  async getLegitCheckType(
    pagination: IPagination,
    filter: PLegitCheckTypeFilter,
    sorts: ISort<ELegitCheckTypeSortField>[],
    signal?: CancelTokenSource
  ): Promise<[ILegitCheckType[], IMeta]> {
    return await this.legitCheckTypeRepo.getLegitCheckType(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getLegitCheckTypeById(id: number): Promise<ILegitCheckType> {
    return await this.legitCheckTypeRepo.getLegitCheckTypeById(id);
  }

  async updateLegitCheckType(
    oldLegitCheckType: PLegitCheckType,
    newLegitCheckType: PLegitCheckType
  ): Promise<void> {
    const diffLegitCheckType = _.pick(
      newLegitCheckType,
      shallowDiff(oldLegitCheckType, newLegitCheckType).updated
    ) as PLegitCheckType;

    await this.legitCheckTypeRepo.updateLegitCheckType(
      oldLegitCheckType.id,
      diffLegitCheckType
    );
  }
}
