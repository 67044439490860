import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EAuthenticationSortField,
  EAuthenticationStatus,
  IAuthentication,
  PAuthenticationFilter,
} from "../entities";
import IAuthenticationRepository from "../repository";
import { IReleaseResultRequest, IUpdateRequest } from "../request/request";
import IAuthenticationUsecase from "../usecase";

export default class AuthenticationUsecase
  implements IAuthenticationUsecase<CancelTokenSource> {
  private authenticationRepo: IAuthenticationRepository<CancelTokenSource>;

  constructor(
    authenticationRepo: IAuthenticationRepository<CancelTokenSource>
  ) {
    this.authenticationRepo = authenticationRepo;
  }

  async getAuthentication(
    pagination: IPagination,
    filter: PAuthenticationFilter,
    sorts: ISort<EAuthenticationSortField>[],
    whitelistedStatuses: EAuthenticationStatus[],
    signal?: CancelTokenSource
  ): Promise<[IAuthentication[], IMeta]> {
    return await this.authenticationRepo.getAuthentication(
      pagination,
      filter,
      sorts,
      whitelistedStatuses,
      signal
    );
  }

  async getAuthenticationByCode(code: string): Promise<IAuthentication> {
    return await this.authenticationRepo.getAuthenticationByCode(code);
  }

  async updateAuthentication(
    code: string,
    updateRequest: IUpdateRequest
  ): Promise<void> {
    await this.authenticationRepo.updateAuthentication(code, updateRequest);
  }

  async authenticatingAuthentication(code: string): Promise<void> {
    await this.authenticationRepo.authenticatingAuthentication(code);
  }

  async addComment(code: string, message: string): Promise<void> {
    await this.authenticationRepo.addComment(code, message);
  }

  async addGuideDetail(code: string, guide_detail_id: number): Promise<void> {
    await this.authenticationRepo.addGuideDetail(code, guide_detail_id);
  }

  async deleteGuideDetail(
    code: string,
    deleted_guide_detail_id: string
  ): Promise<void> {
    await this.authenticationRepo.deleteGuideDetail(
      code,
      deleted_guide_detail_id
    );
  }

  async needAdditionalPicture(
    code: string,
    add_picture_note: string
  ): Promise<void> {
    await this.authenticationRepo.needAdditionalPicture(code, add_picture_note);
  }

  async sendNeedAdditionalPictureNotificationGlobal(): Promise<void> {
    await this.authenticationRepo.sendNeedAdditionalPictureNotificationGlobal();
  }

  async sendNeedAdditionalPictureNotification(code: string): Promise<void> {
    await this.authenticationRepo.sendNeedAdditionalPictureNotification(code);
  }

  async cancelNeedAdditionalPicture(
    code: string,
    password: string
  ): Promise<void> {
    await this.authenticationRepo.cancelNeedAdditionalPicture(code, password);
  }

  async releaseResult(
    code: string,
    request: IReleaseResultRequest
  ): Promise<void> {
    await this.authenticationRepo.releaseResult(code, request);
  }

  async resetResult(code: string, password: string): Promise<void> {
    await this.authenticationRepo.resetResult(code, password);
  }

  async restoreBackup(code: string): Promise<void> {
    await this.authenticationRepo.restoreBackup(code);
  }
}
