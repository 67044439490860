import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EGuideDetailSortField,
  IGuideDetail,
  PGuideDetail,
  PGuideDetailFilter,
} from "../entities";
import IGuideDetailRepository from "../repository";
import IGuideDetailUsecase from "../usecase";

export default class GuideDetailUsecase
  implements IGuideDetailUsecase<CancelTokenSource> {
  private guideDetailRepo: IGuideDetailRepository<CancelTokenSource>;

  constructor(guideDetailRepo: IGuideDetailRepository<CancelTokenSource>) {
    this.guideDetailRepo = guideDetailRepo;
  }

  async getGuideDetail(
    pagination: IPagination,
    filter: PGuideDetailFilter,
    sorts: ISort<EGuideDetailSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IGuideDetail[], IMeta]> {
    return await this.guideDetailRepo.getGuideDetail(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getGuideDetailById(id: number): Promise<IGuideDetail> {
    return await this.guideDetailRepo.getGuideDetailById(id);
  }

  async createGuideDetail(
    guideDetail: PGuideDetail,
    summary_image: Blob,
    detail_images: Blob[]
  ): Promise<void> {
    const formData = new FormData();
    formData.append("title", guideDetail.title);
    formData.append("summary", guideDetail.summary);
    formData.append("description", guideDetail.description);
    formData.append("priority", String(guideDetail.priority));
    formData.append(
      "legit_check_brand_id",
      String(guideDetail.legit_check_brand_id)
    );
    formData.append(
      "product_category_id",
      String(guideDetail.product_category_id)
    );
    formData.append(
      "guide_detail_type_id",
      String(guideDetail.guide_detail_type_id)
    );
    formData.append("is_default", String(guideDetail.is_default));
    formData.append("is_active", String(guideDetail.is_active));
    formData.append("is_example", String(guideDetail.is_example));
    formData.append("summary_image", summary_image);
    for (let img of detail_images) formData.append("detail_images[]", img);

    await this.guideDetailRepo.createGuideDetail(formData);
  }

  async updateGuideDetail(
    id: number,
    guideDetail: PGuideDetail,
    new_summary_image: Blob,
    new_detail_images: Blob[],
    deleted_detail_images: string[]
  ): Promise<void> {
    const formData = new FormData();
    formData.append("title", guideDetail.title);
    formData.append("summary", guideDetail.summary);
    formData.append("description", guideDetail.description);
    formData.append("priority", String(guideDetail.priority));
    formData.append(
      "legit_check_brand_id",
      String(guideDetail.legit_check_brand_id)
    );
    formData.append(
      "product_category_id",
      String(guideDetail.product_category_id)
    );
    formData.append(
      "guide_detail_type_id",
      String(guideDetail.guide_detail_type_id)
    );
    formData.append("is_default", String(guideDetail.is_default));
    formData.append("is_active", String(guideDetail.is_active));
    formData.append("is_example", String(guideDetail.is_example));

    if (new_summary_image)
      formData.append("new_summary_image", new_summary_image);
    for (let img of new_detail_images)
      formData.append("new_detail_images[]", img);
    if (deleted_detail_images.length > 0)
      formData.append("deleted_detail_images", deleted_detail_images.join(","));

    await this.guideDetailRepo.updateGuideDetail(id, formData);
  }

  async deleteGuideDetail(id: number): Promise<void> {
    await this.guideDetailRepo.deleteGuideDetail(id);
  }
}
