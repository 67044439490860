import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EPaymentConfirmationPackageSortField,
  IPaymentConfirmationPackage,
  PPaymentConfirmationPackageFilter,
} from "../entities";
import IPaymentConfirmationPackageRepository from "../repository";
import { IUpdateRequest } from "../request/request";

export default class PaymentConfirmationPackageRepository
  implements IPaymentConfirmationPackageRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private PAYMENT_CONFIRMATION_PACKAGE_ROUTE =
    "/admin/package-payment-confirmation";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PPaymentConfirmationPackageFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getPaymentConfirmationPackage(
    pagination: IPagination,
    filter: PPaymentConfirmationPackageFilter,
    sorts: ISort<EPaymentConfirmationPackageSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IPaymentConfirmationPackage[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.PAYMENT_CONFIRMATION_PACKAGE_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getPaymentConfirmationPackageByCode(
    code: string
  ): Promise<IPaymentConfirmationPackage> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(
        `${this.PAYMENT_CONFIRMATION_PACKAGE_ROUTE}/${code}`
      )
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async updatePaymentConfirmationPackage(
    code: string,
    updateRequest: IUpdateRequest
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.PAYMENT_CONFIRMATION_PACKAGE_ROUTE}/${code}`,
        updateRequest
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
