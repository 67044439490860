import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { produce } from "immer";
import { Reducer } from "redux";
import {
  EInvoicePackageSortField,
  IInvoicePackage,
  PInvoicePackageFilter,
} from "src/core/invoicePackage/entities";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType,
} from "typesafe-actions";

/*
 * state
 */
export interface IInvoicePackageState {
  invoicePackages: IInvoicePackage[];
  loading: boolean;
  error: unknown;
  meta: IMeta;
  filter: PInvoicePackageFilter;
  pagination: IPagination;
  sorts: ISort<EInvoicePackageSortField>[];
  refreshDelay: number;
}

const INITIAL_STATE: IInvoicePackageState = {
  invoicePackages: [],
  loading: false,
  error: null,
  meta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  filter: {},
  pagination: {
    limit: 10,
    offset: 0,
  },
  sorts: [],
  refreshDelay: 5000,
};

/*
 * action types
 */
const FETCH_REQUESTED = "invoicePackage/FETCH_REQUESTED";
const FETCH_SUCCEEDED = "invoicePackage/FETCH_SUCCEEDED";
const FETCH_FAILED = "invoicePackage/FETCH_FAILED";
const SET_FILTER_PAGINATION_SORT = "invoicePackage/SET_FILTER_PAGINATION_SORT";
const SET_REFRESH_DELAY = "invoicePackage/SET_REFRESH_DELAY";
const POLLING_START_REQUESTED = "invoicePackage/POLLING_START_REQUESTED";
const POLLING_STOP_REQUESTED = "invoicePackage/POLLING_STOP_REQUESTED";

/*
 * action creators
 */
export const actions = {
  setFilterPaginationSort: createAction(SET_FILTER_PAGINATION_SORT)<{
    filter: PInvoicePackageFilter;
    pagination: IPagination;
    sorts: ISort<EInvoicePackageSortField>[];
  }>(),

  setRefreshDelay: createAction(SET_REFRESH_DELAY)<{
    refreshDelay: number;
  }>(),

  fetchAsync: createAsyncAction(FETCH_REQUESTED, FETCH_SUCCEEDED, FETCH_FAILED)<
    unknown,
    {
      data: IInvoicePackage[];
      meta: IMeta;
    },
    unknown
  >(),

  pollingStartRequested: createAction(POLLING_START_REQUESTED)(),

  pollingStopRequested: createAction(POLLING_STOP_REQUESTED)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IInvoicePackageState, TActions> = (
  state = INITIAL_STATE,
  action
) => {
  return produce<IInvoicePackageState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.setFilterPaginationSort): {
        const { payload } = action;
        draft.filter = payload.filter;
        draft.pagination = payload.pagination;
        draft.sorts = payload.sorts;
        break;
      }
      case getType(actions.setRefreshDelay): {
        const { payload } = action;
        draft.refreshDelay = payload.refreshDelay;
        break;
      }
      case getType(actions.fetchAsync.request): {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case getType(actions.fetchAsync.success): {
        const {
          payload: { data, meta },
        } = action;
        draft.invoicePackages = data;
        draft.loading = false;
        draft.error = null;
        draft.meta = meta;
        break;
      }
      case getType(actions.fetchAsync.failure): {
        const { payload } = action;
        draft.loading = false;
        draft.error = payload;
        break;
      }
    }
  });
};

export default reducer;
