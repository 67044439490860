import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EPackageSetSortField,
  IPackageSet,
  PPackageSetFilter,
} from "../entities";
import IPackageSetRepository from "../repository";
import IPackageSetUsecase from "../usecase";

export default class PackageSetUsecase
  implements IPackageSetUsecase<CancelTokenSource> {
  private bankRepo: IPackageSetRepository<CancelTokenSource>;

  constructor(bankRepo: IPackageSetRepository<CancelTokenSource>) {
    this.bankRepo = bankRepo;
  }

  async getPackageSet(
    pagination: IPagination,
    filter: PPackageSetFilter,
    sorts: ISort<EPackageSetSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IPackageSet[], IMeta]> {
    return await this.bankRepo.getPackageSet(pagination, filter, sorts, signal);
  }

  async getPackageSetById(id: number): Promise<IPackageSet> {
    return await this.bankRepo.getPackageSetById(id);
  }
}
