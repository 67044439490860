import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import shallowDiff from "shallow-diff";

import {
  EPackageCouponEffectSortField,
  IPackageCouponEffect,
  PPackageCouponEffect,
  PPackageCouponEffectFilter,
} from "../entities";
import IPackageCouponEffectRepository from "../repository";
import IPackageCouponEffectUsecase from "../usecase";

export default class PackageCouponEffectUsecase
  implements IPackageCouponEffectUsecase<CancelTokenSource> {
  private packageCouponEffectRepo: IPackageCouponEffectRepository<
    CancelTokenSource
  >;

  constructor(
    packageCouponEffectRepo: IPackageCouponEffectRepository<CancelTokenSource>
  ) {
    this.packageCouponEffectRepo = packageCouponEffectRepo;
  }

  async getPackageCouponEffect(
    pagination: IPagination,
    filter: PPackageCouponEffectFilter,
    sorts: ISort<EPackageCouponEffectSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IPackageCouponEffect[], IMeta]> {
    return await this.packageCouponEffectRepo.getPackageCouponEffect(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getPackageCouponEffectById(id: number): Promise<IPackageCouponEffect> {
    return await this.packageCouponEffectRepo.getPackageCouponEffectById(id);
  }

  async createPackageCouponEffect(
    packageCouponEffect: PPackageCouponEffect
  ): Promise<void> {
    await this.packageCouponEffectRepo.createPackageCouponEffect(
      packageCouponEffect
    );
  }

  async updatePackageCouponEffect(
    oldPackageCouponEffect: PPackageCouponEffect,
    newPackageCouponEffect: PPackageCouponEffect
  ): Promise<void> {
    const diffPackageCouponEffect = _.pick(
      newPackageCouponEffect,
      shallowDiff(oldPackageCouponEffect, newPackageCouponEffect).updated
    ) as PPackageCouponEffect;

    await this.packageCouponEffectRepo.updatePackageCouponEffect(
      oldPackageCouponEffect.id,
      diffPackageCouponEffect
    );
  }
}
