import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import _ from "lodash";

import { ILegitCheckDetail, PLegitCheckDetail } from "../entities";
import ILegitCheckRepository from "../repository";

export default class LegitCheckRepository implements ILegitCheckRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private LEGIT_CHECK_DETAIL_ROUTE = "/admin/legit-check-detail";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getLegitCheckDetails(
    authentication_id: number
  ): Promise<ILegitCheckDetail[]> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.LEGIT_CHECK_DETAIL_ROUTE}`, {
        params: {
          authentication_id,
        },
      })
    );
    if (err) {
      throw this.getError(err);
    }

    const legitCheckDetails: ILegitCheckDetail[] = response.data.data;
    return legitCheckDetails;
  }

  async getLegitCheckDetailById(id: number): Promise<ILegitCheckDetail> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.LEGIT_CHECK_DETAIL_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }

    return response.data;
  }

  async createLegitCheckDetail(
    legitCheckDetail: PLegitCheckDetail
  ): Promise<void> {
    const [err, response] = await goPromise(
      this.apiClientFactory().post(
        `${this.LEGIT_CHECK_DETAIL_ROUTE}`,
        legitCheckDetail
      )
    );
    if (err) {
      throw this.getError(err);
    }

    return response.data;
  }

  async updateLegitCheckDetail(id: number, price: number): Promise<void> {
    const [err, response] = await goPromise(
      this.apiClientFactory().patch(`${this.LEGIT_CHECK_DETAIL_ROUTE}/${id}`, {
        price,
      })
    );
    if (err) {
      throw this.getError(err);
    }

    return response.data;
  }
}
